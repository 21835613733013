import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
})
export class NotificationOverlayComponent {
  constructor(
    public dialogRef: MatDialogRef<NotificationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
