<div class="tokenized-input w-full">
  <div class="flex gap-x-2">
    <mat-form-field
      *ngFor="let item of tokens; trackBy: trackByFn; let i = index"
      class="flex-1"
      appearance="outline"
    >
      <input
        matInput
        class="text-center text-2xl py-2"
        [ngClass]="{ uppercase: uppercase }"
        type="text"
        maxlength="1"
        [value]="tokens[i]"
        [disabled]="disabled"
        (keyup)="onKeyUp($event, i)"
        (touch)="onTouch($event)"
        (focus)="innerFocus.emit($event)"
        [attr.autocomplete]="autocomplete"
      />
    </mat-form-field>
  </div>
</div>
