<div
  class="bva-sidebar-container"
  data-cy="sidebar.bva-container"
  *ngIf="enabled && !breakpoints.isMobile"
>
  <app-bva-info [msa]="msa"> </app-bva-info>

  <app-bva-intended-provider
    data-cy="sidebar.bva-intended-provider"
    [provider]="intendedProvider"
    [results]="bvaService.results?.providers"
    (clearIntendedProvider)="intendedProvider = null"
  >
  </app-bva-intended-provider>

  <app-bva-intended-providers
    (providerSelected)="intendedProvider = $event"
    (clearIntendedProvider)="clearIntendedProvider()"
    [show]="true"
    [procedure]="searchTrigger.selectedTerm | async"
    [results]="bvaService.results?.providers"
  >
  </app-bva-intended-providers>

  <app-bva-recommended-providers
    *ngIf="bvaService?.providers?.recommended?.length"
  >
  </app-bva-recommended-providers>

  <app-bva-notes> </app-bva-notes>

  <div class="action-buttons-container pad all-2x">
    <zelis-button
      color="accent"
      *ngIf="bvaService?.providers?.recommended?.length || intendedProvider"
      forwardedClass="mr-2 w-[130px]"
      data-cy="sidebar.new-search"
      (clickFn)="newSearch()"
    >
      {{ 'bva_new_search' | translate }}
    </zelis-button>

    <zelis-button
      color="accent"
      *ngIf="bvaService?.providers?.recommended?.length || intendedProvider"
      forwardedClass="w-[130px]"
      (clickFn)="sync()"
    >
      {{ 'bva_sync' | translate }}
    </zelis-button>
  </div>

  <div class="flash-messaging-container pad all-2x">
    <app-flash-message
      [show]="bvaService.syncStatus === 'success'"
      [message]="'bva_sync_success' | translate"
      iconClass="icon-check-circle"
      type="success"
    >
    </app-flash-message>

    <app-flash-message
      [show]="bvaService.syncStatus === 'error'"
      [message]="'bva_sync_error' | translate"
      iconClass="icon-check-circle"
      type="error"
    >
    </app-flash-message>
  </div>
</div>
