import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { DynamicAssetLoaderService } from '@services/dynamic-asset-loader/dynamic-asset-loader.service';
import { WindowService } from '@services/window.service';
import { SettingsService } from '@services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { MetadataProperty, QualtricsMetadata, SearchData } from '@interfaces/qualtricsMetadata.interface';
import { ProductAnalytics } from '@interfaces/product-analytics.interface';

@Injectable({
  providedIn: 'root',
})
export class QualtricsService {
  private metadataObject = new BehaviorSubject<QualtricsMetadata>(null);
  private routeUtilities = new RouteUtilities();
  private qualtricsDisabled = false;

  constructor(
    private assetLoader: DynamicAssetLoaderService,
    private windowService: WindowService,
    private settingsService: SettingsService,
    public router: Router,
  ) {
    this.listenForPageViews();
  }

  public initializeQualtrics() {
    this.settingsService.getSetting('qualtrics_disabled').pipe(
      first(),
      tap(disabled => {
        this.qualtricsDisabled = disabled;
       
        if (!this.qualtricsDisabled) {
          this.loadQualtrics();
        }
      }))
      .subscribe();
  }

  public createMetadataObject(data: ProductAnalytics): void {
    this.waitForPendoVisitorId().pipe(
      map(visitorId => ({
        [MetadataProperty.PendoVisitorId]: visitorId,
        [MetadataProperty.Authenticated]: data.visitor.authStatus,
        [MetadataProperty.Location]: data.visitor.geolocation,
        [MetadataProperty.Gender]: data.visitor.gender,
        [MetadataProperty.PageType]: null,
        [MetadataProperty.PageUrl]: null,
        [MetadataProperty.SearchType]: null,
        [MetadataProperty.SearchMethod]: null,
        [MetadataProperty.SearchTerm]: null,
        [MetadataProperty.NetworkName]: null,
        [MetadataProperty.Client]: data.account.client,
        [MetadataProperty.AccountId]: data.account.id,
      })),
      tap(metadataObject => this.metadataObject.next(metadataObject)))
      .subscribe();
  }

  public updateMetadataObject(searchData?: SearchData): void {
    const pageName = this.routeUtilities.getState();
    
    this.metadataObject.pipe(
      first(metadataObject => !!metadataObject),
      switchMap(metadataObject => {
        const updatedMetadataObject = {
          ...metadataObject,
          [MetadataProperty.PageType]: pageName.replace(/\-/g, ' '),
          [MetadataProperty.PageUrl]: this.getPath(this.router.url),
          [MetadataProperty.SearchType]: searchData?.searchName || null,
          [MetadataProperty.SearchMethod]: searchData?.searchMethod || null,
          [MetadataProperty.SearchTerm]: searchData?.searchTerm || null,
          [MetadataProperty.NetworkName]: searchData?.networkName || null,
        };
        return of(updatedMetadataObject);
      }),
      distinctUntilChanged((prev, curr) =>
        JSON.stringify(prev) === JSON.stringify(curr)),
      tap(metadataObject => {
        this.metadataObject.next(metadataObject);
        if (!this.qualtricsDisabled) this.pushQualtricsDataToWindow(metadataObject);
      }))
      .subscribe();
  }

  private pushQualtricsDataToWindow(metadataObject: QualtricsMetadata): void {
    this.windowService['QSI'].API.unload();
    this.windowService['qualtrics'] = metadataObject;
    this.windowService['QSI'].API.load();
    this.windowService['QSI'].API.run();
  }

  private listenForPageViews(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.routeUtilities.getState()),
      distinctUntilChanged(),
      tap(() => this.updateMetadataObject()),
    ).subscribe();
  }

  private getPath(url: string): string {
    const parsedUrl = this.routeUtilities.parseUrl(url);
    return '/' + parsedUrl.pathObject.segments.slice(0, 3).join('/').toLowerCase();
  }

  private waitForPendoVisitorId(): Observable<string> {
    return interval(50).pipe(
      map(() => this.windowService['pendo'].visitorId),
      first(visitorId => visitorId !== undefined),
      switchMap(visitorId => of(visitorId)),
    );
  }

  private loadQualtrics(): Observable<boolean> {
    return this.assetLoader.loadAsset(
      '../../../assets/scripts/qualtrics.js',
      'script',
    );
  }
}
