import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { UuxNetwork } from "@interfaces/uux-network.model";
import { ProductType } from "@enums/uux-marketing-plans.enum";
import { StorageUtilities } from "@utilities/storage.utilities";
import { Breakpoints } from "@classes/breakpoints.class";
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";
import { RouteUtilities } from "@utilities/route.utilities";
import { Router } from "@angular/router";

@Component({
  selector: 'app-plan-list-selection',
  templateUrl: './plan-list-selection.component.html',
})
export class PlanListSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public pageHeading: string;
  @Input() public description: string;
  @Input() plans: UuxNetwork[];
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  @Output() public refreshPlans: EventEmitter<ProductType> = new EventEmitter();
  public selectedPlan: UuxNetwork;
  public storage: StorageUtilities = new StorageUtilities();
  private selectedProductType: any;

  constructor(
    public breakpoints: Breakpoints,
    private networkSelectWizardService: NetworkSelectionWizardService,
    private routeUtilities: RouteUtilities,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.storage.sessionStorageSet('wizardPlanListPage', true);
    this.selectedProductType = this.storage.sessionStorageGet('wizardSelectedProductType');
    if (!this.selectedProductType) {
      this.refreshPlans.next(ProductType.all);
    } else {
      this.refreshPlans.next(this.plans[0].product_type as ProductType);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plans && this.plans?.length === 1) {
      this.selectedPlan = this.plans[0];
    }
    if (changes.plans && this.plans?.length > 1) {
      const savedPlan = this.networkSelectWizardService.getSavedPlan();
      if (savedPlan) {
        this.selectedPlan = this.plans.find((plan) => plan.displayName === savedPlan.displayName);
      }
    }
  }

  public handleSelect(plan: UuxNetwork): void {
    this.selectedPlan = plan;
    this.networkSelectWizardService.saveSelectedPlan(plan);
  }

  public handleSubmit(): void {
    this.networkSelectWizardService.setSelections();
    this.storage.sessionStorageSet('hasSetWizardSelections', true);
    this.handleNetworkChangeOnSelection();
    this.closeOverlay.emit();
  }

  ngOnDestroy(): void {
    this.storage.sessionStorageRemove('wizardPlanListPage');
  }

  private handleNetworkChangeOnSelection(): void {
    const pageType = this.routeUtilities.getState();
    if (pageType === 'profile' || pageType === 'search') {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }
}
