<div>
  <div class="flex justify-between items-center">
    <div class="flex-grow">
      <p
        *ngIf="title"
        class="font-bold"
        [ngClass]="titleClass"
        [attr.data-cy]="titleCy"
        [innerHTML]="title"
      ></p>
      <p
        *ngIf="caption"
        class="heading-6 tracking-normal"
        [attr.data-cy]="captionCy"
        [innerHtml]="caption"
      ></p>
    </div>

    <button
      *ngIf="dismissable"
      mat-dialog-close
      class="flex flex-none"
      [aria-label]="closeButtonLabel"
      data-cy="dialog-header-close-button"
    >
      <mat-icon svgIcon="dls-times-plain-light"></mat-icon>
    </button>
  </div>
</div>
