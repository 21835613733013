import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayHeaderComponent } from './overlay-header.component';
import { EventPreventDefaultModule, PageContainerModule } from '@zelis/platform-ui-components';
import { ExtendedModule, FlexModule } from '@ngbracket/ngx-layout';
import { GlobalLanguageSelectModule } from '@components/global-header/language/global-language-select.module';
import { MobileHamburgerMenuModule } from '@components/mobile-hamburger-menu/mobile-hamburger-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';

@NgModule({
  declarations: [OverlayHeaderComponent],
  imports: [
    CommonModule,
    ExtendedModule,
    FlexModule,
    GlobalLanguageSelectModule,
    MobileHamburgerMenuModule,
    PageContainerModule,
    TranslateModule,
    EventPreventDefaultModule,
    UnfocusModule,
  ],
  exports: [OverlayHeaderComponent]
})
export class OverlayHeaderModule {}
