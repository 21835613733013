import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialColorDirective } from './material-color/material-color.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MaterialColorDirective],
  exports: [MaterialColorDirective],
})
export class DlsMaterialColorModule {}
