import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GatedEntryOverlayAlphaPrefixComponent } from './gated-entry-overlay-alpha-prefix.component';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatSelectModule } from '@angular/material/select';
import { MemberCardModule } from '@zelis/platform-ui-components';
import { TokenizedInputModule } from '@components/tokenized-input/tokenized-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UnfocusModule } from '../../../directives/unfocus/unfocus.module';
import { GlobalLocationSelectModule } from '../../global-header/location/global-location-select.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GatedEntryOverlayAlphaPrefixComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    DlsButtonModule,
    MemberCardModule,
    TokenizedInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    UnfocusModule,
    GlobalLocationSelectModule,
    MatIconModule,
  ],
  exports: [GatedEntryOverlayAlphaPrefixComponent],
})
export class GatedEntryOverlayAlphaPrefixModule {}
