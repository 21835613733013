import { createAction, props } from '@ngrx/store';
import { SearchParams } from '@interfaces/search-params.model';
import { Results } from '@interfaces/results.interface';

export enum RatesResultsActionsTypes {
  requestRatesResults = '[GET] request rates summary results',
  requestRatesResultsSuccess = '[GET] request rates summary results success',
  requestRatesResultsFailure = '[GET] request rates summary results failed',
  resetRatesResults = '[SET] reset rates summary results',
  updateMetaCacheStatus = '[SET] set meta cache status',
}

export const requestRatesResults = createAction(
  RatesResultsActionsTypes.requestRatesResults,
  props<{ search_params: SearchParams }>()
);

export const requestRatesResultsSuccess = createAction(
  RatesResultsActionsTypes.requestRatesResultsSuccess,
  props<{ results: Results }>()
);

export const requestRatesResultsFailure = createAction(
  RatesResultsActionsTypes.requestRatesResultsFailure,
  props<{ error: any }>()
);

export const resetRatesResults = createAction(
  RatesResultsActionsTypes.resetRatesResults
);

export const updateMetaCacheStatus = createAction(
  RatesResultsActionsTypes.updateMetaCacheStatus,
  props<{ status: string }>()
);
