import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[zelisMaterialColor]',
})
export class MaterialColorDirective {
  @Input('zelisMaterialColor') color = '';

  @HostBinding('class.mat-primary') get primaryClass(): boolean {
    return this.color === 'primary';
  }
  @HostBinding('class.mat-accent') get accentClass(): boolean {
    return this.color === 'accent';
  }
  @HostBinding('class.mat-warn') get warnClass(): boolean {
    return this.color === 'warn';
  }
  @HostBinding('class') get colorClass(): string {
    if (['primary', 'accent', 'warn'].includes(this.color) === false) {
      return `mat-${this.color}`;
    }
    return '';
  }
}
