import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenizedInputComponent } from './tokenized-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [TokenizedInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FlexLayoutModule,
  ],
  exports: [TokenizedInputComponent],
})
export class TokenizedInputModule {}
