import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkAlphaPrefixDialogComponent } from './network-alpha-prefix-dialog.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DlsProgressBarModule } from '@zelis/dls/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TokenizedInputModule } from '@components/tokenized-input/tokenized-input.module';
import { MemberCardModule } from '@zelis/platform-ui-components';
import { ReactiveFormsModule } from '@angular/forms';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';
import { DlsIconModule } from "@zelis/dls/icons";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    DlsButtonModule,
    RouterModule,
    MatDialogModule,
    DlsProgressBarModule,
    MatSelectModule,
    TokenizedInputModule,
    MemberCardModule,
    ReactiveFormsModule,
    UnfocusModule,
    DlsIconModule
  ],
  declarations: [NetworkAlphaPrefixDialogComponent],
  exports: [NetworkAlphaPrefixDialogComponent],
})
export class NetworkAlphaPrefixDialogModule {}
