<div *ngIf="fullScreenState$ | async as fullScreenState">
  <div>
    <ng-content></ng-content>
  </div>
  <mat-dialog-actions align="end" *ngIf="buttons.length > 0">
    <div
      class="flex justify-end w-full"
      [ngClass]="{
        'flex-col space-y-2':
          fullScreenState.isFullScreen && fullScreenStackedButtons,
        'flex-row space-x-2':
          !fullScreenState.isFullScreen || !fullScreenStackedButtons
      }"
    >
      <ng-container *ngFor="let button of buttons; let i = index">
        <ng-template
          [ngIf]="fullScreenState.isFullScreen && fullScreenStackedButtons"
        >
          <zelis-button
            *ngIf="button.isCloseButton && !button.hasInitialFocus"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'flat'"
            mat-flat-button
            [color]="color"
            mat-dialog-close
            [fullWidth]="true"
            [disabled]="!!button.isDisabled"
            size="large"
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="button.hasInitialFocus && !button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'flat'"
            [color]="color"
            cdkFocusInitial
            [fullWidth]="true"
            [disabled]="!!button.isDisabled"
            size="large"
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="button.hasInitialFocus && button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'flat'"
            [color]="color"
            mat-dialog-close
            cdkFocusInitial
            [fullWidth]="true"
            [disabled]="!!button.isDisabled"
            size="large"
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="!button.hasInitialFocus && !button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'basic'"
            [color]="color"
            [fullWidth]="true"
            [disabled]="!!button.isDisabled"
            size="large"
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
        </ng-template>

        <ng-template
          [ngIf]="!fullScreenState.isFullScreen || !fullScreenStackedButtons"
        >
          <zelis-button
            *ngIf="button.isCloseButton && !button.hasInitialFocus"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'basic'"
            [color]="color"
            [disabled]="!!button.isDisabled"
            mat-dialog-close
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="button.hasInitialFocus && !button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'basic'"
            [color]="color"
            [disabled]="!!button.isDisabled"
            cdkFocusInitial
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="button.hasInitialFocus && button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'basic'"
            [color]="color"
            [disabled]="!!button.isDisabled"
            mat-dialog-close
            cdkFocusInitial
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
          <zelis-button
            *ngIf="!button.hasInitialFocus && !button.isCloseButton"
            [attr.data-cy]="'dialog-footer-button-' + i"
            [ngClass]="{ hidden: (button.isHidden | async) }"
            [variant]="button.variant || 'basic'"
            [color]="color"
            [disabled]="!!button.isDisabled"
            (clickFn)="button.onClick?.($event)"
          >
            <mat-icon
              *ngIf="button.icon"
              [svgIcon]="button.icon"
              zelisButtonIcon
            ></mat-icon>
            {{ button.content }}
          </zelis-button>
        </ng-template>
      </ng-container>
    </div>
  </mat-dialog-actions>
</div>
