import { Action, createReducer, on } from '@ngrx/store';
import * as RatesResultsActions from './serp-summary.actions';
import { RatesSummaryResultState, initialState } from './serp-summary.state';

export const RatesSerpResultsReducer = createReducer(
  initialState,
  on(RatesResultsActions.requestRatesResults, (state) => ({
    ...state,
    loading: true,
    error: null,
    _meta: { cache_status: 'incomplete' },
  })),
  on(
    RatesResultsActions.requestRatesResultsSuccess,
    (_state, { results }): any => ({
      _meta: results._meta,
      providers: results.providers,
      loading: false,
      error: null,
    })
  ),
  on(RatesResultsActions.requestRatesResultsFailure, (state, { error }) => ({
    ...state,
    _meta: { cache_status: 'complete', error: true },
    providers: [],
    loading: false,
    error,
  })),
  on(RatesResultsActions.resetRatesResults, () => initialState),
  on(
    RatesResultsActions.updateMetaCacheStatus,
    (state, { status }): any => ({
      ...state,
      _meta: { cache_status: status },
    })
  )
);

export const reducer = (state: RatesSummaryResultState, action: Action) => {
  return RatesSerpResultsReducer(state, action);
};
