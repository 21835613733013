<zelis-dialog data-cy="notification-overlay">
  <zelis-dialog-header
    mat-dialog-title
    [title]="data.headline | translate"
    [dismissable]="!data.noClose"
  >
  </zelis-dialog-header>

  <mat-dialog-content>
    <div
      data-cy="notification-overlay.body"
      [innerHTML]="data.body | translate"
    ></div>
  </mat-dialog-content>

  <zelis-dialog-footer
    [buttons]="data.buttons"
    color="accent"
  ></zelis-dialog-footer>
</zelis-dialog>
