import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { DlsAvatarModule } from '@zelis/dls/avatar';
import { DlsHighlightModule } from '@zelis/dls/highlight';
import { DlsIconModule } from '@zelis/dls/icons';
import { DlsLozengeModule } from '@zelis/dls/lozenge';
import { ListItemSubheadComponent } from './list-item-subhead/list-item-subhead.component';
import { ListItemComponent } from './list-item/list-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { DlsMaterialColorModule } from '@zelis/dls/material-color';

@NgModule({
  imports: [
    CommonModule,
    DlsIconModule,
    DlsAvatarModule,
    DlsLozengeModule,
    MatDividerModule,
    MatRadioModule,
    DlsHighlightModule,
    MatTooltipModule,
    RouterModule.forChild([]),
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRippleModule,
    DlsMaterialColorModule,
  ],
  declarations: [ListItemComponent, ListItemSubheadComponent],
  exports: [ListItemComponent, ListItemSubheadComponent, MatDivider],
  providers: [{ provide: Window, useValue: window }],
})
export class DlsListItemModule {}
